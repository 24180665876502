
.subnet-overview {
    height: 558px !important;
    margin-top: 30px;
    .table-container {
        margin-top: 30px;
        height: 220px;
        overflow-y: scroll;
    }
}
