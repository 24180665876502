.datacenter-proxies{
    .title{
        color: #92F8FF;
        font-weight: bold;
        font-size: 18px;
        padding-left: 0px !important;
        margin-left: 0px !important;
    }
    .content{
        .group-input{
            margin-top: 13px;
            margin-bottom: 30px;
            width: 250px;
            padding-left: 0px !important;
            margin-left: 0px !important;
            .custom-select{
                background: #131419 url('../../assets/icons/arrow-down.svg') !important;
                background-position: 95% center !important;
                background-repeat: no-repeat !important;
                &.open{
                    background: #26272F url('../../assets/icons/arrow-up.svg') !important;
                    background-position: 95% center !important;
                    background-repeat: no-repeat !important;
                }
            }
        }
    }
    .plan-card{
        width: 550px;
        padding: 25px 30px;
        margin-bottom: 10px;
        min-height: 100px !important;
        @media (min-width: 1500px) {
            width: 650px;
        }
        @media (min-width: 1700px) {
            width: 650px;
        }
        .left{
            .title{
                font-size: 14px;
            }
            .number{
                font-weight: 500;
                font-size: 42px;
                color: #FFFFFF;
            }
            .date{
                color: #5F5F5F;
                font-weight: 600;
                font-size: 12px;
                span{
                    color: #FFFFFF;
                }
            }
            .renewal {
                color: #5F5F5F;
                font-weight: 600;
                font-size: 12px;
                span{
                    color: #94DD4B;
                }
            }
        }
        .right{
            margin-left: auto;
            display: flex;
            flex-direction: column;
            .btn{
                width: 124px;
                height: 44px;
                background: #07080D;
                border-radius: 5px;
                font-weight: bold;
                font-size: 12px;
                color:  #AFAFAF;
                &.active, &:hover{
                    color: #92F8FF;
                }
                &:first-child{
                    margin-bottom: 15px;
                }
            }
        }
        .bottom-buttons {
            display: flex;
            justify-content: end;
            align-items: center;
            margin-top: 20px;
            gap: 12px;
            .btn{
                width: 124px;
                height: 44px;
                background: #07080D;
                border-radius: 5px;
                font-weight: bold;
                font-size: 12px;
                color:  #AFAFAF;
                &.active, &:hover{
                    color: #92F8FF;
                }
            }
        }
        .renewal-date{
            color: #5F5F5F;
            font-weight: 600;
            font-size: 12px;
            span{
                color: #FFFFFF;
            }
        }
        .renew-plan{
            display: flex;
            gap: 12px;
            .group-input{
                .custom-select{
                    background: #07080D url('../../assets/icons/arrow-down.svg') !important;
                    background-position: 95% center !important;
                    background-repeat: no-repeat !important;
                    &.open{
                        background: #26272F url('../../assets/icons/arrow-up.svg') !important;
                        background-position: 95% center !important;
                        background-repeat: no-repeat !important;
                    }
                }
                .btn{
                    width: 124px;
                    height: 44px;
                    background: #07080D;
                    border-radius: 5px;
                    font-weight: bold;
                    font-size: 12px;
                    color:  #AFAFAF;
                    &.active, &:hover{
                        color: #92F8FF;
                    }
                    .success{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        //gap: 8px;
                        .success-icon{
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

            }
        }
    }
    .plan-card-content{
        display: flex;
        min-height: 100px !important;
    }
    .cont{
        overflow-x: scroll;
        height: 160px;
        padding-right: 12px;
        margin-top: 12px;
        border-radius: 5px;
    }
    .square-log {
        background: #07080D !important;
        border-radius: 5px;
        width: 100%;
        padding: 15px 20px;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        color: #5F5F5F;
    }

}

.success-open{
    position: relative;
    animation: moving 0.4s ease forwards;
    left: 2px;
}

.success-close{
    position: relative;
    animation: leftmoving 0.4s ease forwards;
    left: 10px;

}

@keyframes moving {
    to {
        left: 10px;
    }
}

@keyframes leftmoving {
    to {
        left: 0px;
    }
}

@media (max-width: 600px) {
    .d-flex{
        flex-direction: column;
        &>*{
            margin-left: 0px !important;
        }
        .ms-auto{
            margin-left: 0px !important;

        }
    }
    .plan-card{
        padding: 20px !important;
        width: 100% !important;
        .btn{
            height: 35px !important;
            width: 100px !important;
        }
        .number{
            font-size: 25px !important;
        }
    }
}