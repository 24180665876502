@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

.balance-title {
    color: white;
    font-size: 12px;
    font-style: normal;
    font-family: Montserrat;
    margin-top: 10px;
}

.input-balance {
    background-color: #07080d;
    border: #07080d 1px solid;
    border-radius: 5px;
    height: 100%;
    padding-left: 20px;
    color: #6e6e6e;
    font-family: Montserrat;
    font-size: 12px;
    margin-top: 5px;
    width: 100%;
    font-weight: 600;
}

.name {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    color: white;
    margin-top: 10px;
    font-family: Montserrat;
    font-weight: 600;
}

.add-balance {
    border-radius: 5px;
    background-color: #07080d;
    border: #07080d 1px solid;
    color: white;
    width: 130px;
    margin-top: -15px;
    font-size: 12px;
    height: 44px;
    font-family: Montserrat;
    font-weight: 600;
}

.balance .balance-card1 .group-input {
    width: 100%;
    margin-left: 0px !important;
    margin-bottom: 15px !important;
    font-family: Montserrat;
    margin-top: 5px;
    .custom-select {
        background: #07080d url('../../../assets/icons/arrow-down.svg') !important;
        background-position: 95% center !important;
        background-repeat: no-repeat !important;
        &.open {
            background: #07080d url('../../../assets/icons/arrow-up.svg') !important;
            background-position: 95% center !important;
            background-repeat: no-repeat !important;
        }
    }
}

.balance .balance-card2 .group-input {
    width: 100%;
    margin-left: 0px !important;
    margin-bottom: 15px !important;
    font-family: Montserrat;
    margin-top: 5px;
    .custom-select {
        background: #07080d url('../../../assets/icons/arrow-down.svg') !important;
        background-position: 95% center !important;
        background-repeat: no-repeat !important;
        &.open {
            background: #07080d url('../../../assets/icons/arrow-up.svg') !important;
            background-position: 95% center !important;
            background-repeat: no-repeat !important;
        }
    }
}

.balance .balance-card3 .group-input {
    width: 150px;
    margin-left: 0px !important;
    margin-bottom: 15px !important;
    font-family: Montserrat;
    margin-top: 5px;
    .custom-select {
        background: #07080d url('../../../assets/icons/arrow-down.svg') !important;
        background-position: 95% center !important;
        background-repeat: no-repeat !important;
        &.open {
            background: #07080d url('../../../assets/icons/arrow-up.svg') !important;
            background-position: 95% center !important;
            background-repeat: no-repeat !important;
        }
    }
}

.balance-header {
    justify-content: space-between;
    display: flex;
}

.balance-card1 {
    height: 260px;
    background-color: #131419 !important;
    padding: 15px;
    margin-top: 30px;
    border-radius: 5px;
}

.balance-card3 {
    height: 530px;
    background-color: #131419 !important;
    padding: 15px;
    margin-top: 30px;
    border-radius: 5px;
}

.balance-card2 {
    height: 260px;
    background-color: #131419 !important;
    padding: 15px;
    margin-top: 10px;
    border-radius: 5px;
}


.balance-titles {
    font-family: Montserrat;
    color: white;
    font-size: 14px;
}

.detail-balance {
    font-size: 12px;
    font-family: Montserrat;
    color: #6e6e6e;
    margin-top: 10px;
}

.table-head {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 12px;
    font-style: normal;
}

.table-header{
    position: sticky;
    top: 0;
    background-color: #131419;
}

.table-body {
    font-family: Montserrat;
    color: #6e6e6e;
    font-size: 10px;
    font-weight: 600;
}

.balance-title1 {
    color: #92f8ff;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-family: Montserrat;
}

.balance-title2 {
    color: white;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-family: Montserrat;
    margin-top: 20px;
}

.delete-balance {
    border: #07080d 1px solid;
    background-color: #07080d;
    border-radius: 5px;
    color: #ff9f9f;
    height: 35px;
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Montserrat;
    font-weight: 600;
    margin-bottom: 3px;
}

.ban-balance {
    border: #07080d 1px solid;
    background-color: #07080d;
    border-radius: 5px;
    color: #ff9f9f;
    font-size: 10px;
    margin-left: 5px;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Montserrat;
    font-weight: 600;
    margin-bottom: 3px;
}

.remove-balance {
    border: #07080d 1px solid;
    background-color: #07080d;
    border-radius: 5px;
    color: #9ef8ff;
    font-size: 10px;
    height: 35px;
    margin-left: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Montserrat;
    font-weight: 600;
    margin-bottom: 3px;
}

.balance-content {
    height: 273px;
    overflow: auto;
}
