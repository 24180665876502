.shop-content{

  .content{
    .plan-option{
      .title{
        color: #92F8FF;
        font-weight: bold;
        font-size: 18px;
        padding-left: 0 !important;
        margin-left: 0 !important;
      }
      .shop-cards{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        margin-left: -12px;
        margin-right: -12px;
        .shop-card{
          flex: 1;
          min-width: 280px;
          background-color: #131419;
          height: 200px;
          border-radius: 5px;
          margin: 0 !important;
          padding: 26px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .card-title {
            font-size: 14px;
            font-weight: bold;
          }
          .card-detail{
            font-size: 12px;
            font-weight: 600;
            color: #5F5F5F;
          }
          .card-footer{
            display: flex;
            justify-content: space-between;
            gap: 10px;
            //flex-wrap: wrap;
            border: none;
            background-color: transparent;
            padding: 0;
            .group-input{
              font-family: Montserrat;
              width: 100%;
              padding-left: 0 !important;
              margin-left: 0 !important;
              .custom-select {
                background: #131419 url('../../assets/icons/arrow-down.svg');
                background-position: 95% center !important;
                background-repeat: no-repeat !important;
                background-color: #07080D;
                &.open {
                  background: #26272F url('../../assets/icons/arrow-up.svg') !important;
                  background-position: 98% center !important;
                  background-repeat: no-repeat !important;
                }
              }
            }
            .purchase{
              background-color: #07080D;
              border-radius: 5px;
              color: #AFAFAF;
              font-size: 12px;
              font-weight: bold;
              border: none;
              outline: none;
              height: 44px;
              padding: 12px 20px;
              &:hover{
                color: #92F8FF;
              }
            }
          }
        }
      }
    }
  }
  .modal-drawer{
    position: fixed;
    //inset: 0;
    background: transparent;
    z-index: 1050;
    //transform: translateX(100%);
    transition: transform 0.5s cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-delay: 2s;
    top: 0;
    right: 0;

    &.active{
      width: 100%;
      height: 100%;
      .modal-drawer-content {
        transform: translateX(0px);
        -webkit-transition: transform 0.5s ease-in-out;
        -moz-transition: transform 0.5s ease-in-out;
        -o-transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
      }
    }

    &.deactive{
      height: 100%;
      .modal-drawer-content {
        transform: translateX(560px);
      }
    }
    .modal-drawer-content{
      opacity: 1;
      width: 560px;
      transform: translateX(560px);
      position: absolute;
      border-radius: 5px;
      right: 0;
      background: #131419;
      height: 100%;
      overflow: auto;
      -webkit-transition: transform 0.5s ease-in-out;
      -moz-transition: transform 0.5s ease-in-out;
      -o-transition: transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out;
      .close-button{
        position: relative;
        top: 20px;
        left: 16px;
        cursor: pointer;
      }
      .drawer-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 80px;
        padding-right: 80px;
        .profile {
          margin-top: 14px;
          display: flex;
          width: 100%;
          justify-content: center;
          .profile-user{
            display: flex;
            align-items: center;
            background: #07080D;
            border-radius: 5px;
            width: 245px;
            height: 33px;
            justify-content: space-between;
            padding-left: 20px;
            padding-right: 20px;
            .user-balance{
              flex: 1;
              color: #92F8FF;
              font-weight: 600;
              font-size: 14px;
              display: flex;
              justify-content: center
            }
            .user-avatar{
              flex: 1;
              position: relative;
              left: 1%;
              width: 40px;
              height: 40px;
            }
            .user-name{
              flex: 1;
              //margin: auto;
              margin-right: 0;
              font-weight: 800;
              font-size: 14px;
              color: white;
              display: flex;
              justify-content: center
            }
          }
        }
        .cart-items{
          margin-top: 80px;
          display: flex;
          flex-direction: column;
          width: 100%;
          .cart-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .cart-header-title{
              font-size: 18px;
              font-weight: bold;
              .number-of-items{
                color: #92F8FF;
              }
            }
            .remove-item{
              width: 44px;
              height: 44px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #07080D;
              border-radius: 5px;
              cursor: pointer;
            }
          }
          .cart-item{
            margin-top: 10px;
            background: #07080D;
            border-radius: 5px;
            padding: 24px 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .item-detail{
              display: flex;
              gap: 12px;
              .item-image{
                width: 38px;
                height: 38px;
                background: #131419;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .item-content{
                //height: 38px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .item-title{
                  color: #92F8FF;
                  font-size: 14px;
                  font-weight: 600;
                }
                .shipping-date{
                  font-size: 12px;
                  color: #5F5F5F;
                  font-weight: 600;
                }
              }
            }
            .item-price{
              font-size: 18px;
              font-weight: 600;
              color: #9BFFA5;
            }
          }
        }
        .coupon{
          margin-top: 48px;
          width: 100%;
          display: flex;
          flex-direction: column;
          .discount-header{
            font-size: 14px;
            font-weight: bold;
          }
          .discount-content{
            margin-top: 20px;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: space-between;
            .input-discount{
              display: flex;
              width: 100%;
              justify-content: space-between;
              background: #07080D;
              border-radius: 5px;
              input{
                background: #07080D;
                border: 0;
                border-radius: 5px;
                width: 100%;
                height: 44px;
                color: #797777;
                font-weight: 600;
                font-size: 12px;
                margin: 0;
                outline: none;
                padding-left: 24px;
              }
              .apply-button{
                font-size: 12px;
                font-weight: 600;
                color: #9BFFA5;
                width: 107px;
                display: flex;
                align-items: center;
                justify-content: center;
                //cursor: pointer;
              }
            }
            .refresh{
              width: 48px;
              height: 44px;
              background: #07080D;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
            .clicked{
              animation: spin 1s linear;
            }
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          }
        }
        .bill{
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 63px;
          .price-list{
            display: flex;
            flex-direction: column;
            padding: 10px 23px;
            gap: 18px;
            .list-item{
              display: flex;
              justify-content: space-between;
              font-weight: 600;
              font-size: 14px;
              .list-item-title{
                color: #6E6E6E;
              }
              .list-item{
                color: #FFFFFF;
              }
              .list-item-discount{
                color: #92F8FF;
              }
            }
          }
          .divider{
            height: 1px;
            background: #2F2F2F;
            margin-top: 18px;
            margin-bottom: 18px;
          }
          .total-price{
            display: flex;
            padding: 0 23px;
            align-items: center;
            justify-content: space-between;
            .total-title{
              font-size: 14px;
              color: #FFFFFF;
              font-weight: bold;
            }
            .total-price-value{
              font-weight: 600;
              font-size: 18px;
              color: #9BFFA5;
            }
          }
        }
        .payment-method{
          display: flex;
          flex-direction: column;
          margin-top: 74px;
          width: 100%;
          .payment-header{
            font-size: 14px;
            font-weight: bold;
          }
          .payment-content{
            display: flex;
            gap: 12px;
            margin-top: 17px;
            justify-content: space-between;
            .group-input {
              width: 100%;
              padding-left: 0;
              .custom-select {
                background: #131419 url('../../assets/icons/arrow-down.svg');
                background-position: 95% center !important;
                background-repeat: no-repeat !important;
                background-color: #07080D;
                &.open {
                  background: #26272F url('../../assets/icons/arrow-up.svg') !important;
                  background-position: 98% center !important;
                  background-repeat: no-repeat !important;
                }
              }
            }
            .checkout{
              border: none;
              border-radius: 5px;
              outline: none;
              background: #07080D;
              color: #AFAFAF;
              font-size: 12px;
              font-weight: bold;
              width: 200px;
              &:hover{
                color: white;
              }
            }
          }
        }
      }

    }
  }
}
@media (max-width: 1200px) {
  .modal-drawer-content{
    width: 100% !important;
  }
}
