.my-account{
    .btn-card{
        background: #07080D;
        border-radius: 5px;
        color: #FFFFFF;
        height: 40px;
        font-weight: 600;
        font-size: 12px;
        outline: 0px;
        border: 0px;
        transition: .3s;
        &:hover{
            color: #ABABAB;
        }
    }
    .content{
        .group-input{
            margin-top: 8px;
            padding-left: 0px !important;
            margin-left: 0px !important;
        }
        .form-bottom{
            display: flex;
            margin-top: 8px;
            margin-bottom: 20px;
            .group-input{
                margin-top: 0px;
                width: 70% !important;
            }
            button:last-child{
                width: 30% !important;
                margin-left: 8px;
                &.w-50{
                    width: 50% !important;
                }
            }
        }
        .login{
            .form-bottom{
                .group-input{
                    width: 80% !important;
                }
                .btn-card{
                    width: 20% !important;
                    color: #92F8FF;
                }
            }
        }
    }
    .dashboard-card{
        .title{
            color: #FFFFFF;
            font-weight: bold;
            font-size: 14px;
        }
    }
    .checkouts{
        .title{
            text-align: left;
        }
        .checks{
            height: auto;
            overflow: hidden;
        }
    }
    .balance-part{
        .top{
            display: flex;
            margin-top: 20px;
            margin-bottom: 20px;
            .money{
                .name{
                    color: #5F5F5F;
                    font-weight: bold;
                    font-size: 12px;
                }
                .number{
                    color: #92F8FF;
                    font-weight: bold;
                    font-size: 20px;
                }
                &:first-child *{
                    text-align: left;
                }
                &:last-child {
                    margin-left: auto;
                    *{
                        text-align: right;
                    }
                }
            }
        }
        .dashboard-card{
            min-height: 100px !important;
            margin-top: 6px;
            &:first-child{
                margin-top: 0px !important;
            }
        }
    }
}

.justify-content-space-between{
    justify-content: space-between;   
}

.align-center{
    align-items: center;
}

.main-table{
    border-collapse:separate; 
    border-spacing: 0 0px;
    border-radius: 5px;
    overflow-x: scroll;
    max-width: 100%;
    .complete{
        color: #73FFB3;
    } 
    td, th{
        background: #131419;
        font-weight: bold;
        font-size: 14px;
        padding: 12px 25px;
        text-align: center;
        img{
            cursor: pointer;
        }
        &:first-child{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            text-align: left;
        }
        &:last-child{
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    .devider td, .devider th{
        background-color:#07080D !important;
        padding: 4px 0px !important;
    }
    thead{
        color: #92F8FF;
    }
    tbody{
        color: white;
    }
    .cont{
        overflow-x: scroll;
        height: 160px;
        padding-right: 12px;
    }
    .square-log{
        background: #07080D !important;
        border-radius: 5px;
        width: 100%;
        padding: 15px 20px;
        text-align: left;
        color: #5F5F5F;
    }
    .form-case{
        vertical-align: top;
    }
    .form{
        margin-top: 30px !important;
        display: flex;
        .btn{
            background: #92F8FF;
            border-radius: 5px;
            height: 44px;
            width: 80px;
            border: 0px;
            font-weight: 600;
            font-size: 12px;
            color: #000000;
        }
        input{
            background: #07080D;
            border-radius: 5px;
            height: 44px;
            width: 170px;
            margin-right: 10px; 
            font-weight: 600;
            font-size: 12px;
            color: #6F6F6F;
            border: 0px;
            padding-left: 15px;
            &::placeholder{
                color: #6F6F6F;
                font-weight: 600;
                font-size: 12px;
            }
        }
    }
}

@media (max-width: 600px) {
    .d-flex{
        flex-direction: column;
        &>*{
            margin-left: 0px !important;
        }
        .ms-auto{
            margin-left: 0px !important;

        }
    }
    .account-card{
        padding: 20px !important;
        width: 100% !important;
        .btn{
            height: 35px !important;
            width: 100px !important;
        }
        .number{
            font-size: 25px !important;
        }
    }
}

.my-account .wallet {
    @media (min-width: 767px) {
        .group-input select, .group-input .custom-select {
            background-position: 88% center !important;
        }
    }
    .buttons{
        display: flex;
        margin-top: 45px;
        &>*:first-child{
            width: 45%;
            margin-right: 6px;
            color: #FF6B6B;
        }
        &>*:last-child{
            width: 55%;
            text-transform: capitalize;
        }
    }
    .credit-cards{
        margin-top: 20px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 240px;
        position: relative;
        padding-bottom: 12px;
        .other-card{
            border-radius: 5px;
            padding: 20px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            background: #07080D;
            border: 1px solid #131419;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            &.o-1{
                width: calc(100% - 16px);
                bottom: 6px;
            }
            &.o-2{
                width: calc(100% - 32px);
                bottom: 0;
            }
        }
        .credit-card{
            height: 100%;
            width: 100%;
            border-radius: 5px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            bottom: 16px;
            padding: 1px;
            height: 138px;
            background: linear-gradient(rgba(146, 248, 255, 0.39), rgba(146, 248, 255, 0.1)) ;
            .credit-inside{
                display: flex;
                flex-direction: column;
                padding: 20px;
                background: #07080D;
                width: 100%;
                border-radius: 5px;
                height: 136px;
                &.back{
                    background: #07080D;
                    padding: 0px;

                    .top{
                        border-radius: 5px;
                        padding: 25px 20px;
                        background: #07080D;
                        border-bottom-left-radius: 0px !important;
                        border-bottom-right-radius: 0px !important;
                    }
                    &>img{
                        height: calc(138px - 68px);
                    }
                }
            }
            .top{
                display: flex;
                .name{
                    color: #FFFFFF;
                    font-weight: bold;
                    font-size: 12px;
                    margin: auto auto auto 0;
                    span{
                        display: inline-block;
                        &.blue{
                            color: #92F8FF;
                        }
                    }
                }
                img{
                    margin: auto 0 auto auto;
                }
            }
            .middle{
                display: flex;
                width: 100%;
                justify-content: space-between;
                color: #92F8FF;
                font-weight: bold;  
                font-size: 14px;
                margin-top: 25px;
                line-height: 17px;
                letter-spacing: 0.2em;
            }
            .bottom{
                margin-left: auto;
                color: #5F5F5F;
                font-weight: bold;
                margin-top: 25px;
                font-size: 10px;
                line-height: 12px;
                span{
                    display: inline-block;
                    margin-left: 1px;
                }
            }
        }
    }
    .added-cards{
        display: flex;
        flex-direction: column;
        .add-card{
            margin-top: 25px;
            margin-bottom: 40px;
        }
        .btn-card{
            width: 80%;
        }
    }
    .title{
        span{
            margin-left: 5px;
            color: #5B5B5B;
        }
    }
    .expiry-cvv{
        display: flex;
        &>.group-input{
            &:first-child{
                width: 70% !important;
                .split-2{
                    display: flex;
                    &>*{
                        margin-right: 6px !important;
                    }
                }
            }
            &:last-child{
                width: 30% !important;
            }
        }
    }
}


.my-account .wallet .card-info{
    display: flex;
    margin-bottom: 15px;
    &:first-child{
        margin-top: 20px;
    }
    &:last-child{
        margin-bottom: 25px;
    }
    .texts{
        .ending{
            color: #5F5F5F;
            font-weight: 600;
            font-size: 12px;
            margin-bottom: 3px;
            span{
                color: #8AEAF1;
            }
        }
        .edit{
            transition: .3s;
            color: #FFFFFF;
            font-weight: 600;
            font-size: 10px;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .img-cont{
        height: 47px;
        position: relative;
        width: 47px;
        margin-right: 19px;
        .visa-cont{
            cursor: pointer;
            background: #07080D;
            border-radius: 5px;
            width: 43px;
            height: 43px;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            img{
                margin: auto;
            }
        }
        .star{
            top: 0;
            right: 0;
            position: absolute;
        }
    }
}


//reset the bootstrap widths
.my-account{

    @media (min-width: 992px){
        .col-lg-3{
            width: 30%;
        }
        .col-lg-3:first-child{
            width: 25% !important;
        }
        .col-lg-6{
            width: 45% !important;
        }
    }
    
}