.subnet-overview {
    height: 558px !important;
    margin-top: 30px;
    .table-container {
        margin-top: 30px;
        height: 436px;
        overflow-y: scroll;
    }
}

.sitelist{
    height: 558px !important;
    margin-top: 30px;
}

.add-subnet{
    height: 558px !important;
    margin-top: 30px;
}


