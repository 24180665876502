@import "./variables";
@import "./mixins";

.dashboard-layout{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

.table-responsive{
    overflow: auto;
}

.main-content{
    position: relative;
    min-height: 100vh;
    //width: calc(100% - 280px);
    color: white;
    padding: 20px 30px;

    .content{
        width: 100%;
        margin: 0px;
        .col-lg-3, .col-lg-6{
            padding-top: 6px;
            padding-left: 3px !important;
            padding-right: 3px !important;
        }
    }
    @include mdMaxBreakPoint{
        width: calc(100% - 55px);
    }
}

.dashboard-card{
    background: #131419;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    width: 100%;
    min-height: 180px;
    margin-right: 20px;
    @media (min-width: 1500px) {
        padding: 25px;
    }
    @media (min-width: 1700px) {
        padding: 35px;
    }
}

.main-card{
    background: #131419;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    width: 100%;
    min-height: 180px;
    margin-right: 20px;
    @media (min-width: 1500px) {
        //padding: 25px !important;
    }
    @media (min-width: 1700px) {
        //padding: 35px !important;
    }
}

.group-input{
    label{
        color: #FFFFFF;
        font-weight: 600;
        font-size: 12px
    }
    input{
        padding: 10px 22px;
    }
    input, select, .custom-select{
        margin-top: 7px;
        background: #07080D;
        border: 0px;
        border-radius: 5px;
        width: 100%;
        height: 44px;
        color: #797777;
        font-weight: 600;
        font-size: 12px;
        margin: 0;
        outline: none;
        .select-selected{
            padding: 12px 22px;
            width: 100%;
            height: 100%;
        }
    }
    select, .custom-select{
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;

        background-image: url('../assets/icons/arrow-down.svg');
        background-position: 95% center;
        background-repeat: no-repeat;
    }
}

.subnet-input{
    label{
        color: #FFFFFF;
        font-weight: 600;
        font-size: 12px
    }
    input{
        padding: 10px 22px;
    }
    input, select, .custom-select{
        margin-top: 7px;
        background: #07080D;
        border: 0px;
        border-radius: 5px;
        width: 100%;
        height: 44px;
        color: #797777;
        font-weight: 600;
        font-size: 12px;
        margin: 0;
        outline: none;
        .select-selected{
            padding: 12px 22px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
    select, .custom-select{
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;

        background-image: url('../assets/icons/arrow-down.svg');
        background-position: 95% center;
        background-repeat: no-repeat;
    }
}

::-webkit-scrollbar{
    width: 3px;
    height: 100%;
}

::-webkit-scrollbar-track {
    background: #07080D !important;
}

::-webkit-scrollbar-thumb {
    background: #4F5052;
}

.check-container{
    display:block;
    position:relative;
    cursor:pointer;
    margin-bottom:16px;
    font-size:22px;
    -webkit-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.check-container input{
    position:absolute;
    opacity:0;
    cursor:pointer;
    height:0;
    width:0
}
.check-container .checkmark{
    position:absolute;
    top:0;
    left:0;
    height:16px;
    width:16px;
    background: #92F8FF;
    box-sizing:border-box;
    border-radius:3px
}
.check-container .checkmark:after{
    content:"";
    position:absolute;
    display:none
}
.check-container input:checked~.checkmark:after{
    display:block
}
.check-container .checkmark:after{
    left:6px;
    top:1px;
    width:5px;
    height:12px;
    border:solid #000;
    border-width:0 2px 2px 0;
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg)
}
.check-container.gray .checkmark{
    border:2px solid rgba(98,98,98,.3)
}
.check-container.gray .checkmark:after{
    border-width:0 2px 2px 0
}
