@media (min-width: 1200px) {
    .main-content{
        width: calc(100% - 329px);
        padding: 20px 80px;
    }
    .sidebar{
        width: 329px;
    }
}
@media (min-width: 1700px) {
    .main-content{
        width: calc(100% - 329px);
        padding: 20px 120px;
    }
    .sidebar{
        width: 329px;
    }
}
